import {
  Badge,
  Box,
  Flex,
  Image,
  useMultiStyleConfig,
  Alert,
  AlertIcon,
  CloseButton,
  Text,
  Slide,
} from '@chakra-ui/react';
import React, { useMemo, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import S from 'string';

import { useCheckSettings, useCurrent } from 'features/auth';
import { PrivateRouteConfig } from 'routes/routeConfig';
import { FEATURE_FLAG } from 'types/featureFlag';

import { TopNavbarDispatch } from './TopNavbarDispatch';
import { TopNavbarDivider } from './TopNavbarDivider';
import { TopNavbarDropdown } from './TopNavbarDropdown';
import { TopNavbarDropdownItemLink } from './TopNavbarDropdownItemLink';
import { TopNavbarItemLink } from './TopNavbarItemLink';
import { TopNavbarItemLinkIcon } from './TopNavbarItemLinkIcon';
import { TopNavbarItemRouterLink } from './TopNavbarItemRouterLink';
import { TopNavbarOperations } from './TopNavbarOperations';
import { TopNavbarOrders } from './TopNavbarOrder';
import { TopNavbarRouting } from './TopNavbarRouting';
import { TopNavbarTenant } from './TopNavbarTenant';
import { TopNavbarUser } from './TopNavbarUser';

const transformStr = (str: string) => S(str).humanize().titleCase().s;

const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

export const TopNavbar: React.FC = () => {
  const styles = useMultiStyleConfig('TopNavbar', {});
  const { data } = useCurrent();
  const location = useLocation();
  const userName = useMemo(() => transformStr(data?.current?.user?.name ?? ''), [data]);
  const isCustomerClient = useMemo(() => data?.current?.isCustomerClient ?? false, [data]);
  const customerName = useMemo(() => transformStr(data?.current?.customer?.name ?? ''), [data]);
  const isSuperAdmin = useMemo(() => data?.current?.user?.isSuperAdmin ?? false, [data]);

  const [hasSettings] = useCheckSettings();

  const isRoutingAllowed = useMemo(() => {
    const result =
      (data?.current?.customerSettings?.includes(FEATURE_FLAG.ROUTING_FEATURE) || false) &&
      (isSuperAdmin || hasSettings('view_shift_plans') || hasSettings('view_routing_report'));
    return result;
  }, [data, isSuperAdmin, hasSettings]);

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (data?.current?.settings?.find((setting) => setting?.name === 'warning_message')?.value) {
      setIsOpen(true);
    }
  }, [data, setIsOpen, location]);

  const isMigratedRolesEnabled = hasSettings('migrated_roles_feature');

  const switchBetweenMigratedUrl = useMemo(
    () => ({
      roles: isMigratedRolesEnabled ? '/staff/roles' : `${REDIRECT_URL}roles`,
    }),
    [isMigratedRolesEnabled]
  );

  return (
    <Box data-testid="top-navbar" className="top-navbar" sx={styles.container}>
      <Slide
        unmountOnExit={true}
        in={isOpen}
        direction={'top'}
        style={{ zIndex: 9999, width: '100%', height: '10%' }}
      >
        <Alert
          top={'2'}
          left={'0'}
          right={'0'}
          width={'97%'}
          status="error"
          margin={'auto'}
          borderRadius={'3xl'}
          position={'absolute'}
          backgroundColor={'red.500'}
        >
          <AlertIcon color={'white'} />
          <Text width={'full'} wordBreak={'break-word'}>
            {data?.current.settings.find((setting) => setting.name === 'warning_message')?.value}
          </Text>
          <CloseButton
            alignSelf="flex-start"
            onClick={() => setIsOpen(false)}
            _hover={{ transform: 'rotate(180deg)' }}
          />
        </Alert>
      </Slide>

      <Flex h="full">
        {/* Logo */}
        <Flex h="full" mr="1.5" p="0.5rem">
          <Link to={'/'}>
            <Image src="/logo192.png" alt="FleetPanda's Logo" h="38px" w="38px" />
          </Link>
        </Flex>
        {/* Menu */}
        {data && (
          <>
            {!isCustomerClient ? (
              <Flex alignSelf="flex-end" columnGap="0.25rem" zIndex={3}>
                <TopNavbarItemLink href={'/'}>Dashboard</TopNavbarItemLink>
                <TopNavbarDispatch />
                <TopNavbarOrders />
                {isRoutingAllowed && <TopNavbarRouting />}
                {hasSettings(FEATURE_FLAG.CUSTOMERS_FEATURE) && (
                  <TopNavbarItemRouterLink to="/customers">Customers</TopNavbarItemRouterLink>
                )}
                <TopNavbarOperations />
                <TopNavbarItemRouterLink to="/pricing">Pricing</TopNavbarItemRouterLink>

                {(isSuperAdmin || hasSettings(FEATURE_FLAG.HANDLE_TENANT_ROLES)) && (
                  <TopNavbarDropdown trigger="Staff">
                    <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['roles']}>
                      Roles
                    </TopNavbarDropdownItemLink>
                  </TopNavbarDropdown>
                )}
                {hasSettings(FEATURE_FLAG.REPORTS_FEATURE) && (
                  <TopNavbarDropdown trigger="Reports">
                    <TopNavbarDropdownItemLink href={`${REDIRECT_URL}reports`}>
                      Report Dashboard
                    </TopNavbarDropdownItemLink>
                    <TopNavbarDropdownItemLink href={'/reports/bol-report'}>
                      BOL Report
                    </TopNavbarDropdownItemLink>
                    {hasSettings(FEATURE_FLAG.ASSET_INVENTORY_TRACKING_FEATURE) && (
                      <TopNavbarDropdownItemLink href="/reports/inventory">
                        Inventory
                      </TopNavbarDropdownItemLink>
                    )}
                    {hasSettings(FEATURE_FLAG.CARD_LOCK_TRANSACTION_FEATURE) && (
                      <TopNavbarDropdownItemLink href="/reports/sales-report">
                        Sales Report
                      </TopNavbarDropdownItemLink>
                    )}
                    {hasSettings(FEATURE_FLAG.CARD_LOCK_TRANSACTION_FEATURE) && (
                      <TopNavbarDropdownItemLink href="/reports/tax-report">
                        Tax Report
                      </TopNavbarDropdownItemLink>
                    )}
                  </TopNavbarDropdown>
                )}
                {data?.current.customerSettings.includes(FEATURE_FLAG.TANK_MONITORING) && (
                  <TopNavbarItemRouterLink to={`/${PrivateRouteConfig.TANK_MONITORS.path}`}>
                    Tank Monitor
                  </TopNavbarItemRouterLink>
                )}
              </Flex>
            ) : (
              <Flex alignSelf="flex-end" columnGap="0.25rem">
                <TopNavbarItemRouterLink to="/customer-portal/dashboard">
                  Dashboard
                </TopNavbarItemRouterLink>
                <TopNavbarDropdown trigger="Orders">
                  <TopNavbarDropdownItemLink href="/customer-portal/delivery-orders">
                    Delivery Orders
                  </TopNavbarDropdownItemLink>
                  {/* not needed for now but can be used in future as recurring order feature is not required in customer portal for now */}
                  {/* <TopNavbarDropdownItemLink href="/customer-portal/recurring-orders">
                    Recurring Orders
                  </TopNavbarDropdownItemLink> */}
                </TopNavbarDropdown>
                <TopNavbarItemRouterLink to="/customer-portal/graph">Graph</TopNavbarItemRouterLink>
              </Flex>
            )}
            {/* Right side */}
            <Flex ml="auto" px="0.1875rem">
              {env !== 'production' && (
                <>
                  <Badge
                    alignSelf="center"
                    colorScheme="whatsapp"
                    cursor="default"
                    mr="4"
                    title={`This is a ${env} environment`}
                    userSelect="none"
                    variant="solid"
                  >
                    {env}
                  </Badge>
                </>
              )}
              <TopNavbarTenant customers={data.current.accessibleCustomers} />
              {isSuperAdmin && (
                <TopNavbarItemLinkIcon
                  icon="cog"
                  variant="solid"
                  alt="Settings"
                  title="Settings"
                  href="/settings"
                />
              )}
              <TopNavbarDivider />
              <TopNavbarUser userName={userName} customerName={customerName} />
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  );
};

export type TopNavbar = typeof TopNavbar;
